
@import '../../styles/variables'
@import '../../../node_modules/mapbox-gl/dist/mapbox-gl.css'
@import '../../lib/core/sass/bp'

article
  position: relative
  display: block
  margin: 0
  width: 100%
  padding: 8px
  box-sizing: border-box
  background-color: $color-grey-warm-dark
  overflow: auto

.inner
  border-radius: 8px
  flex-direction: column
  +bpw(md)
    flex-direction: row
  display: flex
  justify-content: center

.teaser
  width: 100%
  margin-bottom: 8px
  +bpw(md)
    width: 25%
    margin: 8px
  a
    position: relative
    font-family: $font-main
    text-decoration: none
    color: $color-grey-warm-dark
    font-weight: 600
    text-transform: uppercase
    flex-direction: row
    display: flex
    border-radius: 8px
    overflow: hidden
    transition: all 0.45s
    &:hover
      color: white
      .icon
        background-color: $color-red
      &:before
        left: 0
    &:after
      content: ''
      background-color: white
      position: absolute
      left: 0
      top: 0
      right: 0
      height: 100%
      z-index: 1
      transition: all 0.45s
      border-radius: 8px
    &:before
      content: ''
      background-color: $color-red
      position: absolute
      left: 100%
      top: 0
      right: 0
      height: 100%
      z-index: 2
      transition: all 0.45s
  span
    position: relative
    z-index: 3
    padding: 8px
    display: flex

.icon
  background-color: $color-bronze-light
  width: 45px
  height: 45px
  transition: all 0.45s ease-out
  svg
    width: 100%
    height: 100%
    fill: white

.stretch
  flex: 1
  line-height: 29px
  padding-left: 15px !important

.backward
  .icon
    transform: rotate(180deg)
  a
    &:before
      left: 0
      top: 0
      right: 100%
    &:hover
      &:before
        right: 0 !important
        left: 0 !important
