
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

button
  cursor: pointer
  display: inline-block
  vertical-align: middle
  text-decoration: none
  font-family: $font-main
  font-weight: 600
  font-size: 14px
  overflow: hidden
  position: relative
  border: 1px solid white
  box-sizing: border-box
  border-radius: 4px
  opacity: 0.25
  transition: opacity 0.75s ease-in-out
  &:focus
    outline: none
    box-shadow: none
  &:before
    content: ''
    position: absolute
    left: 0
    top: 0
    right: 100%
    height: 100%
    background-color: $color-red
    transition: right 0.45s
  > span
    position: relative
    white-space: nowrap
    display: block
    line-height: 28px
    padding: 0 10px
    text-transform: uppercase
    transition: color 0.45s
    > span
      display: inline-block
    svg
      height: 100%
      max-width: 14px
      height: 100%
      position: absolute
      top: 0
      bottom: 0
      left: 12px
      transition: fill 0.45s
  &:hover
    > span
      color: white
      fill: white !important
    &:before
      right: 0
  &.icon
    span > span
      margin-left: 24px
  &.checked
    opacity: 1
