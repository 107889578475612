
@import '../../styles/variables'
@import '../../../node_modules/mapbox-gl/dist/mapbox-gl.css'
@import '../../lib/core/sass/bp'

.map::v-deep

  .mapboxgl-popup
    max-width: 300px !important
    +bpw(lg)
      max-width: 350px !important

  .mapboxgl-popup-close-button
    font-size: 22px
    line-height: 22px
    padding: 0 6px
    color: $color-red

  .mapboxgl-popup-content
    padding: 22px 32px
    color: black
    text-align: left
    font-family: $font-main
    box-shadow: 0 0 15px rgba(0, 0, 0, .25)

    h3

      margin: 0
      padding: 0
      text-transform: uppercase
      font-size: 14px
      +bpw(lg)
        font-size: 17px

    p
      margin: 6px 0 0 0
      line-height: 18px
      font-size: 12px
      +bpw(lg)
        margin: 12px 0 0 0
        line-height: 21px
        font-size: 15px


article
  position: relative
  display: block
  margin: 0
  width: 100%
  height: 70vH
  +bpw(lg)
    height: 100vH
  background-color: $color-grey-light
  overflow: auto
  box-sizing: border-box

.alternate
  display: flex
  justify-content: center
  vertical-align: center
  align-items: center
  align-content: center
  flex: 1
  height: 100%
  .cw-picture
    opacity: 0.5
  button
    border: 5px solid white

.inner
  position: absolute
  left: 0
  right: 0
  top: 0
  bottom: 0
  overflow: hidden

.map
  position: absolute
  width: 100%
  height: 100%

  &.pointer
    &::v-deep
      canvas
        cursor: pointer !important

.zoom
  display: none
  position: absolute
  left: 50%
  bottom: 15px
  background: white
  transform: translateX(-50%)
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.25)
  white-space: nowrap
  button
    display: inline-block
  button:nth-child(n+3)
    margin-left: 8px
  +bpw(xs)
    display: block
    padding: 4px
  +bpw(sm)
    padding: 8px
  +bpw(lg)
    bottom: auto
    left: 50%
    top: 84px
  +bpw(xl)
    padding-left: 16px
    button
      margin-left: 8px


.layer
  display: none
  +bpw(lg)
    display: block
    position: absolute
    left: 50%
    bottom: 32px
    padding: 8px
    padding-left: 16px
    background: white
    transform: translateX(-50%)
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25)
    white-space: nowrap
    button
      display: inline-block
      margin-left: 8px

.label
  display: none
  +bpw(xl)
    display: inline-block
    font-family: $font-main
    text-transform: uppercase
    font-weight: bold
    font-size: 12px

.marker
  width: 36px
  height: 50px
  display: inline-block
  cursor: pointer
  svg
    path
      fill: $color-bronze !important
    circle
      fill: white !important
